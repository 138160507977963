import axios from './axios';

export const getUsers=params=>{
  return axios({
    url: '/user/search',
    method: 'get',
    params
  })
};

export const getUserInfo =params=>{
  return axios({
    url: '/api/user',
    method: 'get',
    params
  })
};

export const editUser =params=>{
  const id = params.userId;
  // delete params.userId;
  return axios({
    url: `/user/${id}/role`,
    method: 'put',
    params: {
      role: params.role
    }
  })
};

export const getAdminList = () => {
  return axios({
    url: '/user/admin',
    method: 'get',
  })
};

export const addAdminList = (data) => {
  return axios({
    url: '/user/admin',
    method: 'post',
    data
  })
};

export const deleteAdmin = id => {
  return axios({
    url: `/user/admin/${id}`,
    method: 'delete'
  })
}

export const getSupervisor = id => {
  return axios({
    url: '/user/supervisor',
    method: 'get'
  })
}