import { memo, useState, useEffect } from 'react'
import { Select, Modal, Spin, Tag, Tooltip, Input, Button } from 'antd'
import { useVersion } from '@/hooks/version'
import { useParams } from 'react-router-dom'
import { STATE_RELASED } from './../../../utils/constant'
import { useTranslation } from 'react-i18next'
import { getVersion } from '@/utils/util'

export default memo(function({
    readonly,
    currentLayout,
    onSave,
    reload,
}) {
    const { t } = useTranslation()
    const params = useParams()
    const { versions, versioning, onVersions } = useVersion(params.id, true)
    // const [current, setCurrent] = useState({})
    const [isDesc, setIsDesc] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [desc, setDesc] = useState('')

    // const onGetCurrent = () => {
    //     if (params.version === 'latest') {
    //         setCurrent(versions[0])
    //     } else {
    //         setCurrent(versions.find(o => o.version === params.version))
    //     }
    // }

    const onModal = () => {
        setIsDesc(!readonly && currentLayout?.state === STATE_RELASED)
    }

    const onConfirm = async () => {
        try {
            setConfirming(true)
            await onSave(desc)
            // onVersions()
            reload()
        } catch (error) {
            
        } finally {
            setTimeout(() => {
                setConfirming(false)
            }, 2000)
        }
    }

    // useEffect(() => {
    //     onGetCurrent()
    // }, [params.id, params.version, versions])

    useEffect(() => {
        onModal()
    }, [currentLayout])

    return (
        <>

            <div>
                {
                    !currentLayout ? null :
                    <div className='f-s-c'>
                        {
                            !currentLayout ? null :
                            <Tooltip title={currentLayout.pct}>
                                <Tag>
                                    <div style={{maxWidth: 120}} className='white-1'>
                                        <span>{getVersion(currentLayout)}</span>
                                        &nbsp;
                                        {currentLayout.pct || ''}
                                    </div>
                                </Tag>
                            </Tooltip>
                        }
                        {/* <Select size='small' className='w-90' value={current.version}>
                            {
                                (versions || []).map(o => (
                                    <Select.Option value={o.version} key={o.version}>{o.version}</Select.Option>
                                ))
                            }
                        </Select> */}
                    </div>
                }
            </div>
            <Modal
                title={t('scene.layout.desc')}
                open={isDesc}
                mask={true}
                keyboard={false}
                closeIcon={false}
                maskClosable={false}
                footer={false}
            >
                <Input
                    value={desc}
                    placeholder={t('scene.layout.desc')}
                    onChange={e=>setDesc(e.target.value.trim())}
                />
                <footer className="f-e-c m-t-20">
                    <Button disabled={!desc} type="primary" loading={confirming} onClick={onConfirm}>{t('common.confirm')}</Button>
                </footer>
            </Modal>
        </>
    )
})
