import { useState, useEffect, memo } from 'react'
import { Button, Spin } from 'antd'
import { getLatestLayout } from '@/axios/axios.scene'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { useStatus } from '@/hooks/status'

export default memo(function({ scene, plantMap }) {
    const [details, setDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const { map } = useStatus()

    const onGetDetails = async () => {
        if (!scene || !scene.plant) {
            setDetails([])
            return
        }
        try {
            setLoading(true)
            const res = await getLatestLayout(scene.id)
            setLoading(false)
            // res.Updates = [
            //     { Version: 'V1.0', Pct: 'hahahhahahahhah' },
            //     { Version: 'V1.1', Pct: 'fsahahahhahahahhah' },
            // ]
            const list = [
                { label: 'Layout', value: scene.name },
                { label: 'Plant', value: plantMap.has(scene.plant) ? plantMap.get(scene.plant).plant : '' },
                { label: 'Version', value: `V${scene.version_major}.${scene.version_minor}` },
                { label: 'Status', value: map.get(Number(scene.state)) },
                { label: 'Create By', value: res.CreateUser },
                { label: 'Update By', value: res.update_by },
                { label: 'PCT Number', value: res.Updates.map(o => ({ label: o.Pct, value: o.Version })), isList: true },
            ]
            setDetails(list)
            setVisible(true)
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        onGetDetails()
    }, [scene])
    if (!scene || !scene.plant) return null
    return (
        <div className={`${ visible ? 'w-230 bg-color-fff shadow-app' : 'h-40 w-35 o-h b-no' } f-s-0 b-eee b-no-tb b-no-r p-r animated  fadeInRight`}>
            <Button
                type='primary'
                icon={visible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                ghost={!visible}
                className={'animated fadeInRight p-a-xr-yt m-t-5 z-100'}
                onClick={() => setVisible(!visible)}
            ></Button>
            {
                !visible ?
                null :
                <Spin spinning={loading}>
                    <header className='h-40 f-s-c p-lr-10 f-s-16 f-w-600 bg-color-f0f0f0 animated fadeInRight'>Detail:</header>
                    <ul className='animated fadeInRight'>
                        {
                            details.map(o => (
                                <li key={o.label} className='w-per-100 d-f m-t-20'>
                                    <section className='f-w-600 f-s-14 f-e-c w-100 m-r-10 f-s-0 h-20 l-h-20'>{o.label}:</section>
                                    {
                                        o.isList ?
                                        <div className='f-1 o-h f-column'>
                                            {
                                                o.value.map(c => (
                                                    <section key={c.value} className='f-s-c f-s-12 f-s-c c-app m-tb-2'>
                                                        <span className='f-s-0'>{c.value}</span>-
                                                        <span className='f-1 white-1' title={c.label}>{c.label}</span>
                                                    </section>
                                                ))
                                            }
                                        </div> :
                                        <section className='f-s-12 f-s-c c-app'>{o.value}</section>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </Spin>
            }
        </div>
    )
})

