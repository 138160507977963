import { Table, Tabs, Tooltip, Modal, Button, Form, Radio, Input, message } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useStatus } from '@/hooks/status'
import imgWaiting from '@/assets/waiting.png'
import imgApprove from '@/assets/Approve.png'
import imgDeclined from '@/assets/declined.png'
import { declineTask, approveTask, getTaskList } from '@/axios/axios.task'
import { LinkOutlined } from '@ant-design/icons'


export default function Task() {
  // const account = useSelector(state => state.account)
  const { t } = useTranslation()
  const { map, approveOptions } = useStatus()
  const [type, setType] = useState('submitted')
  const [tasks, setTasks] = useState([])
  const [page, setPage] = useState(1)
  const [size] = useState(20)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowProve, setIsShowProve] = useState(false)
  const [isProving, setIsProving] = useState(false)
  const [result, setResult] = useState('')
  const [comment, setComment] = useState('')
  const [taskId, setTaskId] = useState('')
  const [layout, setLayout] = useState({
    plant: '',
    design_id: '',
    x: 0,
    y: 0,
  })

  const tabs = [
    {
      label: t('task.tab-sub'),
      key: 'submitted'
    },
    {
      label: t('task.tab-appro'),
      key: 'to_review'
    },
  ]

  const columns = [
    { title: 'Layout', dataIndex: 'name'},
    { title: t('task.col.docType'), width: 120, dataIndex: 'doc_type'},
    { title: t('task.col.status'), dataIndex: 'statusName'},
    { title: t('task.col.version'),dataIndex: 'design_version'},

    { title: t('task.col.plant'), dataIndex: 'plant'},
    // { title: t('task.col.pe'), dataIndex: 'process_engr_name'},
    { title: t('task.col.suser'), dataIndex: 'submit_user_name'},
    { title: t('task.col.stime'), dataIndex: 'submit_time', disApproval: true},
    { title: t('task.col.approvel'), dataIndex: 'approval_engr_name', disApproval: true},
    { title: t('task.col.comment'), dataIndex: 'comment'},
    { title: t('task.col.displayState'), dataIndex: 'approval_state', align: "center", render(text, record) {
      return (
        <div className='f-c'>
          {
          Number(text) === 1 ? 
          <Tooltip title={t('task.state-check')}>
            <img 
              src={imgWaiting} 
              alt="" 
              className={`${type === 'to_review' ? "c-p" : ""}`}
              onClick={
                type !== 'to_review' ? null : ()=>onShowProve(record)} />
          </Tooltip> :
          Number(text) === 2 ?
          <Tooltip title={t('task.state-approved')}>
            <img src={imgApprove} alt="" />
          </Tooltip> :
          Number(text) === 3 ?
          <Tooltip title={t('task.state-rejected')}>
            <img src={imgDeclined} alt="" />
          </Tooltip> : null
          }

          <Link className="d-b"  to={`/scene/editor/${record.plant}/read/${record.layout_id}/${record.design_id}/${record.design_version}`}>
          <Button type="primary" shape="circle" size='small' className='m-l-10'>

            <LinkOutlined className='' />
            </Button>
          </Link>
        </div>
      )
    }},
  ]

  const onGetList =(pageNo)=>{
    if (isLoading) return false
    setIsLoading(true)
    const newPage = pageNo || page
    getTaskList({
      type,
      page: newPage,
      size
    })
    .then(res=>{
      setIsShowProve(false)
      setPage(newPage)
      setTasks(res.map(o=>({
        ...o,
        statusName: map.get(Number(o.status))
      })))
    })
    .finally(()=>{
      setIsLoading(false)
    })
  }

  const onShowProve =record=>{
    setResult('')
    setTaskId(record.id)
    setComment('')
    setIsShowProve(true)
    setIsProving(false)
  }

  const onConfirm =()=>{
    if (!result) {
      message.error(t('task.approve-err-t'))
      return false
    }
    const action = Number(result) === 2 ? approveTask : declineTask
    setIsProving(true)
    action(taskId, comment)
      .then(()=>{
        onGetList(page)
      }) 
      .finally(()=>{
        setIsProving(false)
      })
  }

  const onContextMenu =(record, e)=>{
    e.preventDefault()
    setLayout({
      plant: record.plant,
      design_id: record.design_id,
      x: e.clientX,
      y: e.clientY,
    })
  }

  const onHideMenu =()=>{
    setLayout({
      plant: '',
      design_id: ''
    })
  }

  useEffect(()=>{
    onGetList(1)
  }, [type])

  useEffect(()=>{
    window.addEventListener('click', onHideMenu)
    return ()=>{
      window.removeEventListener('click', onHideMenu)
    } 
  })

  return (
    <article>
      <header>
        <Tabs
          activeKey={type}
          items={tabs}
          onChange={setType}
        />
      </header>
      <main>
        <Table
          bordered
          rowKey='id'
          loading={isLoading}
          dataSource={tasks}
          columns={columns.filter(o => type !== 'to_review' || !o.disApproval)}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: (event) => {onHideMenu(record)}, // 点击行
              onDoubleClick: (event) => {},
              onContextMenu: (event) => { onContextMenu(record, event) },
              onMouseEnter: (event) => {}, // 鼠标移入行
              onMouseLeave: (event) => {},
            };
          }}
        ></Table>
      </main>
      {
        !!layout.plant ?
        <aside className='w-110 translate-y--50 p-tb-5 bg-color-fff b-r-4 shadow-app p-f' style={{left: layout.x + 5, top: layout.y}}>
          <Link to={`/scene/editor/${layout.plant}/read/${layout.id}/${layout.design_id}/latest`}>
            <Button type="link">Layout</Button>
          </Link>
        </aside> : null
      }
      <Modal
        title={t('task.approve-title')}
        open={isShowProve}
        confirmLoading={isProving}
        onOk={onConfirm}
        onCancel={()=>setIsShowProve(false)}
      >
        <Form {...layout} size='small'>
          <Form.Item label={t('task.approve-result')} required>
            <Radio.Group 
              name="radiogroup" 
              value={result} 
              options={approveOptions} 
              onChange={e=>setResult(e.target.value)}/>
          </Form.Item>

          <Form.Item label={t('task.approve-comment')}>
            <Input.TextArea 
              rows={4} 
              value={comment}
              onChange={e=>setComment(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </article>
  )
}