import { ColorPicker, Divider, Form, Input, Select, Tag } from "antd"
import { memo, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { usePlant } from '@/hooks/plant'
import { useSceneDicts } from '@/hooks/scen_dicts'
import { TAG_BASIC_FILL } from '@/utils/storage'

export default memo(function TagsBasic({
    isEquip,
    isArrow,
    isNew,
    readonly,
    layer,
    plant,
    setPlant,
    onChange
}) {
    const { t } = useTranslation()
    const { plants } = usePlant()
    const [ basic, setBasic ] = useState({})
    const { sceneLevelMap, sceneCates, sceneCateChildren } = useSceneDicts()
    const [colors, setColors] = useState([])
    const fill = useRef('')

    const onChangeBasic =(k, v, isSelect)=>{
        const newBasic = {
            ...basic,
            [k]: v
        }

        if (k === 'scene_id') {
            newBasic.cate_id = ''
        }
        setBasic(newBasic)
        if (isSelect) {
            onChange(newBasic)
        }
    }

    const onChangeLayer =()=>{
        let flag = false 
        let attrs = ['name', 'code']
        for (let i=0; i<attrs.length; i++) {
            if (String(basic[attrs[i]]) !== String(layer[attrs[i]])) {
                flag = true
                break
            }
        }
        if (!flag) return false
        onChange(basic)

    }

    const onChangeColor =(e, v)=>{
        onChangeBasic('fill', v, true)
        fill.current = v
    }

    const onOpenChange =(v)=>{
        if (v) {
            fill.current = ''
            
        } else {

            if (colors.includes(fill.current)) return false
            setColors(colors =>[ fill.current, ...colors ].filter(Boolean).slice(0, 20))

        }
    }

    useEffect(()=>{
        setBasic({
            ...layer
        })
    }, [layer])

    useEffect(()=>{
        try {
            setColors(JSON.parse(localStorage.getItem(TAG_BASIC_FILL)).slice(0, 20))
        } catch (error) {
            setColors([])
        }
    }, [])

    useEffect(()=>{
        localStorage.setItem(TAG_BASIC_FILL, JSON.stringify(colors))
    }, [colors])

    return (
        <>
            <Divider orientation='left' orientationMargin='10%'>
                <Tag color='blue'>{t('scene.editor.basic')}</Tag>
            </Divider>
            <Form.Item label="ID">
                <h3 value={basic.key} disabled className='m-no-b'>{basic.key}</h3>
            </Form.Item>
            {/* layout所属场景 */}
            {
                isEquip || isArrow ? null :
                    <Form.Item label={'Plant'}>
                        <Select 
                            value={plant} 
                            onChange={e => setPlant(e)} 
                            disabled={readonly || !isNew}>
                            {
                                plants.map(o => (
                                    <Select.Option
                                        key={o.code}
                                        value={o.code}
                                    >{o.plant}({o.name})</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
            }
            {/* 图层名称 */}
            {
                isEquip || isArrow ? null :
                    <Form.Item label={t('scene.editor.title')}>
                        <Input
                            disabled={!basic.id || readonly}
                            value={basic.name || ""}
                            onChange={e => onChangeBasic('name', e.target.value)}
                            onBlur={onChangeLayer}
                        />
                    </Form.Item>
            }
            {/* 编号 */}
            {
                isEquip || isArrow ? null :
                    <Form.Item label={t('scene.editor.code')}>
                        <Input
                            disabled={!basic.id || readonly}
                            value={basic.code || ""}
                            onChange={e => onChangeBasic('code', e.target.value)}
                            onBlur={onChangeLayer}
                        />
                    </Form.Item>
            }

            {/* 场景类别 */}
            {
                isArrow ? null :
                <Form.Item label={t('scene.editor.scene-cate')}>
                    <Select 
                        value={String(basic.scene_id)} 
                        disabled={!basic.id || readonly} 
                        onChange={e => { onChangeBasic('scene_id', e, true)}}>
                        {
                            sceneCates.filter(o => Number(o.scen_level) === Number(basic.level)).map(o => (
                                <Select.Option
                                    key={o.id}
                                    value={String(o.id)}
                                >{o.category}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            }
            {/* 类别--三级类别 */}
            {
                isArrow ? null :
                <Form.Item label={t('scene.editor.layer-cate')}>
                    <Select 
                        value={String(basic.cate_id)} 
                        disabled={!basic.id || readonly} 
                        onChange={e => { onChangeBasic('cate_id', e, true) }}>
                        {
                            sceneCateChildren.filter(o => String(o.scen_category_id) === String(basic.scene_id)).map(o => (
                                <Select.Option
                                    key={o.id}
                                    value={String(o.id)}
                                >{o.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
            }
            {/* 场景目录 */}
            {
                isArrow ? null :
                <Form.Item label={t('scene.editor.scene-menu')}>
                    <Tag color='green'>{sceneLevelMap.get(String(basic.level))}</Tag>
                </Form.Item>
            }
            {/* 目录属性 */}
            {
                isArrow ? null :
                <Form.Item label={t('scene.editor.menu')}>
                    <Tag color='green'>{basic.level}</Tag>
                </Form.Item>
            }

            {/* 背景颜色 */}
            {
                isEquip ? null :
                    <Form.Item label={t('scene.editor.color')}>
                        <ColorPicker disabled={!basic.id || readonly}
                            value={basic.fill || ""}
                            size='small'
                            presets={[{
                                label: 'Recent',
                                colors: colors,
                            }]}
                            onOpenChange={onOpenChange}
                            onChange={onChangeColor} />
                    </Form.Item>
            }
        </>
    )
})
