import { Form, Select, Input, Tooltip, Button, message, Modal, Checkbox } from "antd"
import { memo, useState } from "react"
import { useParams } from 'react-router-dom'

import { useSceneDicts } from '@/hooks/scen_dicts'
import { useTranslation } from "react-i18next"
import { CHANGE_FILTERS } from "@/reducers/editor"
import { ExperimentOutlined, ExportOutlined, PaperClipOutlined } from "@ant-design/icons"
import { downBlob } from '@/utils/file'
import { exportTree } from '@/axios/axios.scene'
import { useEquipment } from '@/hooks/equipment'
import { formatterNumber } from '@/utils/util'

export default memo(function Params({
    dispatch,
    history,
    filters,
    tree,
}) {
    const { t } = useTranslation()
    const params = useParams()
    const { onEquipName } = useEquipment(true, history)

    const { scenes, sceneCates } = useSceneDicts()
    const [exporting, setExporting] = useState(false)
    const [exportingEquip, setExportingEquip] = useState(false)
    const [withLog, setWithLog] = useState(false)
    const [showWithLog, setShowWithLog] = useState(false)

    const onChangeName =e=>{
        dispatch({ type: CHANGE_FILTERS, data: { ...filters, name: e.target.value } })
    }

    const onChangeLevel = (v)=>{
        dispatch({ type: CHANGE_FILTERS, data: { ...filters, minLevel: v, cate: '' } })
    }
    
    const onChangeCate = (v)=>{
        dispatch({ type: CHANGE_FILTERS, data: { ...filters, cate: v } })
    }

    const onExport = async () => {
        setWithLog(false)
        // const modal = Modal.confirm({
        //     title: t('common.notice'),
        //     forceRender: true,
        //     content: (
        //         <div>
        //             <Checkbox defaultChecked={withLog} onChange={e=>setWithLog(e.target.checked)}> With edit history </Checkbox>
        //         </div>
        //     ),
        //     onOk: () => {
                return new Promise(async (resolve, reject) => {
                    try {
                        setExporting(true)
                        /* 
                            {
                                "id": "xxx",
                                "title": "plant auto",
                                "length": 123.22,
                                "width": 342.22,
                                "area": 232222.1111,
                                "level": 2,
                                "scene_id": 22,
                                "cate_id": 23,
                                "asset_id": 24
                            },
                        */

                        const data = []
                        const deepTree = (arr) => {
                            arr.forEach(o => {
                                const level = Number(o.level)
                                // const { width, height } = o
                                // const area = Number(width) * Number(height)
            
                                const obj = {
                                    id: o.key,
                                    title: level === 6 ? o.desc : level === 7 ? onEquipName(o) : o.name,
                                    length: level > 5 ? 0 : (Number(formatterNumber(o.width)) || 0),
                                    width: level > 5 ? 0 : (Number(formatterNumber(o.height)) || 0),
                                    // area: 
                                    level,
                                    code: o.code,
                                    station_id: 0,
                                    x: Number(formatterNumber(o.x)),
                                    y: Number(formatterNumber(o.y)),
                                    // scene_id: 
                                    // cate_id: 
                                    // asset_id:
                                }
                                if (level > 5) {
                                    obj.area = 0
                                    if (level === 6) {
                                        obj.scene_id = 0
                                        obj.cate_id = Number(o.ws) || 0
                                        obj.asset_id = ''
                                        obj.station_id = Number(o.ws) || 0
                                    } else if (level === 7) {
                                        const equip = history.equip(o.equip)
                                        obj.scene_id = equip ? (Number(equip.scene_id) || 0) : 0
                                        obj.cate_id = equip ? (Number(equip.cate_id) || 0) : 0
                                        obj.asset_id = equip ? equip.asset_no : ''
                                    }
                                } else {
                                    const { width, height } = o
                                    const area = Number(width) * Number(height)
                                    obj.area = area ? Number(formatterNumber(area)) || 0 : area || 0
                                    obj.scene_id = Number(o.scene_id) || 0
                                    obj.cate_id = Number(o.cate_id) || 0
                                    obj.asset_id = ''
                                }
                                data.push(obj)
                                if (o.children) {
                                    deepTree(o.children)
                                }
                            })
                        }
                        deepTree(tree)
                        const downFile = await exportTree(params.id, params.design, { lines: data, withLog })
                        downBlob(`${Date.now()}.xlsx`, downFile)
                        // modal.destroy()
                        resolve()
                        setShowWithLog(false)

                    } catch (error) {
                        reject()
                        message.error(error.message)
                    } finally {
                        setExporting(false)
                    }
                })
        //     },
        //     onCancel: () => {
        //         modal.destroy()
        //     }
        // })
        
    }
    
    return (
        <>
            <Form layout='inline' size='small'>
                <Form.Item label={t('scene.editor.scene-menu')} className="w-200">
                    <Select
                        value={filters.minLevel}
                        size='small'
                        allowClear
                        onChange={onChangeLevel}
                        options={scenes
                            .map(o => ({ label: o.name, value: o.level }))}
                    >
                    </Select>
                </Form.Item>
                <Form.Item label={t('scene.editor.scene-cate')} className="w-200">
                    <Select
                        value={filters.cate}
                        size='small'
                        allowClear
                        onChange={onChangeCate}
                        options={sceneCates
                            .filter(o => o.scen_level <= filters.minLevel)
                            .map(o => ({ label: o.category, value: o.id }))}
                    >
                    </Select>
                </Form.Item>
                <Form.Item label={''} className="w-200">
                    <Input.Search value={filters.name} placeholder={t('scene.editor.title')} onChange={onChangeName}></Input.Search>
                </Form.Item>
                <Form.Item label='' className="">
                <Tooltip title={t("common.export")}>
            <Button
                className='animated fadeInLeft m-r-10'
                type='link'
                loading={exporting}
                icon={<ExportOutlined />}
                onClick={() => setShowWithLog(true)}
            ></Button>
            </Tooltip>
                </Form.Item>
            </Form>
            <Modal
                title={t('common.notice')}
                open={showWithLog}
                confirmLoading={exporting}
                onOk={onExport}
                onCancel={() => {setShowWithLog(false); setWithLog(false)}}
            >
                <div>
                    <Checkbox checked={withLog} onChange={e=>setWithLog(e.target.checked)}> With edit history </Checkbox>
                </div>
            </Modal>
        </>
    )
})
