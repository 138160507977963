import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Modal, Form, Input, Spin, Select } from 'antd'
import { SearchOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { addCategory, deleteCategory, editCategory } from '@/axios/axios.category'
import { useSceneDicts } from '@/hooks/scen_dicts'

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
}

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
}

export default function Category() {
  const { t } = useTranslation()
  const { scenes, sceneCates, sceneQueryChildrenCates, sceneCateMap, sceneCateChildren, isFetchingSceneCateChildren, onGetAllSceneCateChildren } = useSceneDicts()
  const [type, setType] = useState('')
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const [query ,setQuery] = useState('')
  const [level, setLevel] = useState('')

  const columns = [
    { title: t('setting.category.name'), dataIndex: 'name' },
    { title: t('setting.category.f_cate'), dataIndex: 'scen_category_id', render(text) {
      return sceneCateMap.has(String(text)) ? sceneCateMap.get(String(text)).scen_name : text
    }},

    { title: t('common.action'), key: 'action', render(record) {
      return <section className='f-s-c'>
          <Button type='link' onClick={() => onModal(record, true)}>{t('common.edit')}</Button>

        <Button type='link' danger onClick={()=>onDelete(record)}>{t('common.delete')}</Button>
      </section>
    }}
  ]

  const onGetListByQuery =()=>{
    onGetAllSceneCateChildren({ query, level })
  }

  const onReset = ()=>{
    setQuery('')
    setLevel('')
    onGetAllSceneCateChildren()
  }

  const onChangeLevel = (v) => {
    setLevel(v)
    // onGetAllSceneCateChildren()
    // onGetListByQuery()
    onGetAllSceneCateChildren({ query, level: v })
  }

  const onDelete =(record)=>{
    Modal.confirm({
      title: t('common.notice'),
      content: t('common.notice-del'),
      onOk: ()=>{
        return new Promise(async (resolve, reject)=>{
          try {
            await deleteCategory(record.id)
            onGetAllSceneCateChildren()
            resolve()
          } catch (error) {
            reject()
          }
        })
      }
    })
  }

  const onKeyUp = (e) => {
    // console.log(e)
    if (e.code === "Enter") {
      // onGetAllSceneCateChildren()
    onGetAllSceneCateChildren({ query, level })

    }
  }

  const onModal =(record, isEdit)=>{
    window.requestIdleCallback(()=>{
      form.setFieldsValue(isEdit ? record : { 
        name: '',
        scen_category_id: ''
      })
    })
    setType(isEdit ? 'edit' : 'add')
  }

  const onClose =()=>{
    setType('')
  }

  const onSave =async (arg)=>{
    setSaving(true)
    try {
      const code = arg.id
      const isEdit = type === 'edit'
      if (isEdit) delete arg.id
      isEdit ? await editCategory(code, arg) : await addCategory(arg)

      setSaving(false)
      onClose()
      onGetAllSceneCateChildren()
    } catch (error) {
      setSaving(false) 
    }
    
  }
  
  return (
    <article>
      <header className='f-s-c p-b-10'>
      <Form layout='inline'>
          <Form.Item label={t('scene.type.col-level')}>
            <Select value={level} onChange={onChangeLevel} style={{width: 150}} allowClear>
              {
                scenes.map(o=>(
                  <Select.Option allowClear key={o.level} value={o.level}>{o.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label={t('common.keyword')}>
            <Input value={query} allowClear placeholder={t('common.keyword')} onChange={e=>setQuery(e.target.value)} onKeyUp={onKeyUp} />
          </Form.Item>
        </Form>
        <Button type='primary' ghost icon={<SearchOutlined />} className='m-r-20' onClick={onGetListByQuery}>{t("common.search")}</Button>
        <Button type='primary'  icon={<PlusOutlined />} onClick={onModal}>{t("common.add")}</Button>
        {/* <Button type='primary' danger icon={<ReloadOutlined />} className='m-l-20' onClick={onReset}>{t("common.reset")}</Button> */}

      </header>
      <Table
        rowKey='id'
        loading={isFetchingSceneCateChildren}
        dataSource={sceneQueryChildrenCates || sceneCateChildren}
        columns={columns}
      ></Table>
      <Modal
        title={t("setting.category.title")}
        width={590}
        open={!!type}
        footer={null}
        onCancel={onClose}
      >
        <Spin spinning={saving}>
          <Form {...layout} name="nest-messages" className='p-t-20' form={form} onFinish={onSave} validateMessages={validateMessages}>

            <Form.Item
              name={['id']}
              label={t("setting.category.name")}
              rules={[
                { required: false },
              ]}
              hidden
            >
              <Input  />
            </Form.Item>

            <Form.Item
              name={['scen_category_id']}
              label={t("setting.category.f_cate")}
              rules={[
                { required: true },
              ]}
            >
              <Select disabled={type==='edit'}>
                {
                  sceneCates.map(o=>(
                    <Select.Option key={o.id} value={o.id}>{o.category}({o.scen_name})</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name={['name']}
              label={t("setting.category.name")}
              rules={[
                { required: true },
              ]}
            >
              <Input  />
            </Form.Item>

            
            
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
              }}
            >
              <Button type="primary" htmlType="submit" loading={saving}>
                {t("common.save")}
              </Button>
              <Button danger ghost htmlType="reset" className='m-lr-15'>
                {t("common.reset")}
              </Button>
              <Button type='primary' danger onClick={onClose}>
                {t("common.cancel")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </article>
  )
}