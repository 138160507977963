import { useState, useEffect, useRef } from 'react'
import { getPlants } from '@/axios/axios.dict'

export function usePlant() {
  const [plants, setPlants] = useState([])
  const [plantMap, setMap] = useState(new Map())
  const [loadingPlant, setLoadding] = useState(false)
  const plantRef = useRef('')

  const onGetAllPlants=(params)=>{
    setLoadding(true)
    getPlants(params)
      .then(res=>{
        setPlants(res)
        setMap(new Map(res.map(o=>[o.code, o])))
      })
      .catch((err)=>{
        setPlants([])
      })
      .finally(()=>{
        setLoadding(false)
      })
  }


  useEffect(()=>{
    if (!plantRef.current) {
      plantRef.current = true
      onGetAllPlants()
    }
  }, [])

  return {
    plantMap,
    loadingPlant,
    plants,
    onGetAllPlants,
  }
}
