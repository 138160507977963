import axios from './axios';

export const getPlants =(params)=>{
  return axios({
    url: '/plant',
    method: 'get',
    params,
  })
};

export const delPlant =(code)=>{
  return axios({
    url: `/plant/${code}`,
    method: 'delete'
  })
};

export const editPlant =(code,data)=>{
  return axios({
    url: `/plant/${code}`,
    method: 'put',
    data
  })
};

export const addPlant =(data)=>{
  return axios({
    url: '/plant',
    method: 'post',
    data
  })
};

export const addCategory =(data)=>{
  return axios({
    url: '/elemcategory',
    method: 'post',
    data
  })
};

export const getCategorys=()=>{
  return axios({
    url: '/elemcategory',
    method: 'get',
  })
};

export const delCategory=(id)=>{
  return axios({
    url: `/elemcategory/${id}`,
    method: 'delete'
  })
};

export const getEleCount =(params)=>{
  return axios({
    url: '/element/count',
    method: 'get',
    params
  })
};

export const getElements =(params)=>{
  return axios({
    url: `/element`,
    method: 'get',
    params
  })
};

export const addElement =data=>{
  return axios({
    url: '/element',
    method: 'post',
    data
  })
};

export const delElement =id=>{
  return axios({
    url: `/element/${id}`,
    method: 'delete'
  })
};

export const getAllSceneTypes =()=>{
  return axios({
    url: `/scenario`,
    method: 'get'
  })
};

export const editSceneType =(data)=>{
  const scenarioId = data.id;
  delete data.id;
  return axios({
    url: `/scenario/${scenarioId}/category`,
    data,
    method: 'put'
  })
};

export const getEquipments =(params)=>{
  return axios({
    url: `/equipment`,
    method: 'get',
    params
  })
};

export const exportEquips = () => {
  return axios({
    url: `/equipment/export`,
    method: 'get',
    responseType: 'blob'
  })
}

