import { memo, useState, useEffect } from 'react'
import { useDraw } from '@/hooks/editor/draw'
import { useArrow } from '@/hooks/editor/arrow'
import { useSelection } from '@/hooks/editor/selection'
import Size from './size'
import { useMove } from '@/hooks/editor/move'
import { useScale } from '@/hooks/editor/scale'
import { useSelector } from 'react-redux'
import { useCopy } from '@/hooks/editor/copy'
// import ZWT from '@/assets/zhanweitu.png'
import DeviceExist from '@/assets/device-exist.png'
import DeviceEmpty from '@/assets/device-empty.png'
import Coord from './coord'
import Grid from './grid'
import { MODE_ARROW, MODE_BROWSE, MODE_COPY, MODE_DRAW, MODE_SELECT } from '../../../utils/constant'

export default memo(function ({
  zoom,
  history,
  parent,
  entities,
  arrows,
  equipments,
  selectedIds,
  mode,
  dispatch,
  selectedEntity,
  selectedArrow,
  readonly,
  isEquip,
  isArrow,
  isShowChildren,
  isShowGrid,

  selectedEquipids,
  selectedEquip,

  copyType,
  showLevels,
  copyTarId,
}) {
  const common = useSelector(state => state.common)
  const dicts = useSelector(state=>state.dicts)

  const { scale, width, height } = useScale(parent, zoom)
  const { onCopyEvent } = useCopy({ history, dispatch, parent, scale, selectedEntity, tarId: copyTarId, copyType, })

  const { isMoving, onMouseDown, onDestroy } = useMove(history, dispatch, isEquip, isEquip ? selectedEquip : selectedEntity, parent, scale, readonly)
  const { onSelect, onSelectParent, } = useSelection(history, dispatch, isMoving)
  const { text, isDrawing, path, onClick, onMouseMove } = useDraw(dispatch, history, parent, mode, scale, selectedEntity)
  const { arrowText, isArrowing, arrowPath, onArrowClick, onArrowMouseMove, onCatArrowPath } = useArrow(dispatch, history, parent, mode, scale, selectedEntity)
  const [entityList, setEntityList] = useState([])
  const [points, setPoints] = useState([])
  const [arrowList, setArrowList] = useState([])
  const [imgMap, setImgMap] = useState({})

  const onGetEntities =()=>{
    if (!parent) return
    let id = parent.id
    if (isShowChildren) {
      if (selectedEntity && selectedEntity.pid === parent.id) {
        id = selectedEntity.id
      }
    }
    const list = []
    for (let i in entities) {
      const entity = entities[i]
      if (!showLevels.includes(String(entity.level))) continue
      if (isShowChildren) {
        if ((!id || entity.id === parent.id || entity.pid === parent.id || i.startsWith(id)) && entity.level < 6) {
          list.push(entity)
        }
      } else {
        if (entity.pid === id || entity.id === id) {
          list.push(entity)
        }
      }
    }
    setEntityList(list)

    const equiplist = []
    for (let i in equipments) {
      const equip = equipments[i]
      if (!showLevels.includes(String(equip.level))) continue
      if (isShowChildren) {
        if (!id || i.startsWith(id)) {
          equiplist.push(equip)
        }
      } else {
        if (equip.pid === id) {
          equiplist.push(equip)
        }
      }
    }
    setPoints(equiplist)
    
  }

  const onGetArrows = () => {
    if (!parent) return
    const id = parent.id
    const list = []
    if (showLevels.includes('arrow')) {
      for (let i in arrows) {
        const obj = {...arrows[i]}
        if (isShowChildren) {
          if (!id || i.startsWith(id)) {
            list.push(obj)
          }
        } else {
          if (obj.pid === id || (id === 'root' && (!obj.pid || obj.pid === 'root'))) {
            list.push(obj)
          }
        }
      }
      list.forEach(o => {
          o.path = onCatArrowPath([o.x, o.y], [o.ex, o.ey], history.entity(o.pid || 'root'))
        }
      )
      setArrowList(list)
    } else {
      setArrowList([])
    }
  }

  useEffect(() => {
    onGetEntities()
    return () => {
      onDestroy()
    }
  }, [entities, parent, selectedEntity, isShowChildren, showLevels])

  useEffect(() => {
    onGetArrows()
  }, [arrows, parent, selectedArrow, isShowChildren, scale])

  useEffect(() => {
    const { factors } = dicts
    const newMap = {}
    Object.values(factors).forEach(o=>{
      newMap[o.id] = o.icon
    })
    setImgMap(newMap)
  }, [dicts.factors, entities])

  return (
    <div
      id='layout-editor'
      className={`
        editor-container p-a-xc-yt t-c bg-color-f6f6f6
        ${[MODE_DRAW, MODE_ARROW].includes(mode) ? (parent?.level >= 5 ? "c-cell" : "c-crosshair") : ""} 
        mode-${mode === MODE_BROWSE ? 'browse ' : mode === MODE_COPY ? 'copy ' : ''} 
        level-${parent.level >=5 ? "point" : "rect" }
      `}
      style={{height: common.height - 170, width}}
      pid={parent.id}
    >
      <div className='h-per-100 w-per-100 o-a p-r' style={{ position: "relative" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: width*zoom, height: height*zoom }}
          className={zoom <= 1 ? 'm-a layout-editor-svg' : 'layout-editor-svg'}
          onClick={
            mode === MODE_DRAW ? 
              onClick : 
              mode === MODE_COPY ? 
              onCopyEvent :
              mode === MODE_ARROW ?
              onArrowClick : 
              onSelect
          }
          onMouseMove={
            mode === MODE_DRAW ? 
            onMouseMove : 
            mode === MODE_ARROW ?
            onArrowMouseMove : 
            null
          }
          onDoubleClick={(mode === MODE_BROWSE || mode === MODE_COPY) ? null : onSelectParent}
          onMouseDown={mode === MODE_SELECT ? onMouseDown : null}
        >
          
          {
            entityList.map(o => (
              <g
                key={o.id}
                className={`${selectedIds.includes(o.id) ? "selected" : ""}`}
                data-id={o.id}>
                <rect
                  className='cell-rect'
                  data-id={o.id}
                  x={parent.id === o.id ? 0 : Number(o.x) * scale - Number(parent.x) * scale}
                  y={parent.id === o.id ? 0 : Number(o.y) * scale - Number(parent.y) * scale}
                  width={Number(o.width) * scale}
                  height={Number(o.height) * scale}
                  strokeWidth={1}
                  stroke={'#0f0'}
                  fill={o.fill}
                ></rect>
                {
                  <foreignObject
                    x={Number(o.text_x) * scale + (parent.id === o.id ? 0 : (Number(o.x) * scale) - Number(parent.x) * scale)}
                    y={Number(o.text_y) * scale + (parent.id === o.id ? 0 : (Number(o.y) * scale) - Number(parent.y) * scale)} 
                    className='o-v p-e-none'
                  >
                    <div xmlns="http://www.w3.org/1999/xhtml" className='o-v p-r p-e-none'>
                      <div className='p-a-xl-yt origin-lt' style={{ transform: `rotate(${o.text_rotate||0}deg)`, transformOrigin: 'left top' }}>
                        <svg>
                          <g data-id={o.id}>
                            <text
                              data-id={o.id}
                              alignmentBaseline="text-before-edge"
                              x={0}
                              y={0}
                              fontSize={o.text_size}
                              fill={o.text_fill}
                              style={{scale: zoom}}
                            >{o.name}</text> 
                          </g>
                        </svg>
                      </div>
                    </div>
                  </foreignObject>
                
                
              }
            </g>
          ))
        }
        <g id='arrow-wrapper' style={{overflow: 'visible'}}>
          {
            arrowList.map(o => (
              <g 
                key={o.id}
                className={`${selectedArrow && selectedArrow.id ===o.id ? "selected" : ""}`}
                data-id={o.id}
                data-type='arrow'>
                  {
                    
                    selectedArrow && selectedArrow.id ===o.id ?
                    <path
                      className='animated fadeIn'
                      stroke={ '#ff6300'}
                      strokeWidth={ 6}
                      fill={ '#ff6300'}
                      style={{opacity: 0.2}}
                      d={o.path}
                      data-id={o.id}
                      data-type='arrow'></path> :
                      null
                  }
                  <path
                    className='animated fadeIn'
                    stroke={o.fill}
                    strokeWidth={3}
                    fill={o.fill}
                    style={{opacity: 1}}
                    d={o.path}
                    data-id={o.id}
                    data-type='arrow'></path>
                  
              </g>
            ))
          }
        </g>
        {
          points.map(o => (
            <g 
              key={o.id}
              className={``}
              data-id={o.id}
              data-type='equip'
            >
              {
                !selectedEquipids.includes(o.id) ? null :
                <rect
                  className='cell-rect'
                  data-id={o.id}
                  data-type='equip'
                  x={parent.id === o.id ? 0 : Number(o.x) * scale - Number(parent.x) * scale - 20}
                  y={parent.id === o.id ? 0 : Number(o.y) * scale - Number(parent.y) * scale - 20}
                  width={40}
                  height={40}
                  strokeWidth={3}
                  stroke={'#f00'}
                  fill={'transparent'}
                ></rect>
              }
              <image 
                data-id={o.id}
                data-type='equip'
                // href={imgMap[o.factor_id] || ZWT} 
                href={!!o.asset_no ? DeviceExist : DeviceEmpty} 
                x={Number(o.x) * scale - Number(parent.x) * scale - 20}
                y={Number(o.y) * scale - Number(parent.y) * scale - 20}
                height="40" 
                width="40"
              />
              
            </g>
          ))
        }
        {
          mode === MODE_DRAW && isDrawing ?
            <path
              className='animated fadeIn'
              stroke='#000'
              strokeWidth={2}
              fill='rgba(255,0,0,.3)'
              d={path}></path> :
            null
        }
        {
          mode === MODE_ARROW && isArrowing ?
            <path
              className='animated fadeIn'
              stroke='#f00'
              strokeWidth={2}
              fill='rgba(255,0,0,.6)'
              d={arrowPath}></path> :
            null
        }
        {
          mode === MODE_COPY ? null :
          <Size
            scale={scale}  
            isMoving={isMoving}
            readonly={readonly}
            history={history}
            parent={parent}
            dispatch={dispatch}
            selectedEntity={selectedEntity}
          />
        }
        {
          isShowGrid ?
          <Grid 
            parent={parent}
            scale={scale}
            width={width*zoom}
            height={height*zoom}
          /> :
          null
        }
        {
          <image
            className="svg-image"
            style={{ display: "none" }}
            href={''}
            x="0"
            y="0"
            height="100"
            width="100" />
        }
      </svg>
    </div>
    <Coord 
        parent={parent}
        scale={scale}
      />
  </div>
)
})
