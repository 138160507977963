import { memo, useState } from "react"
import { DeleteOutlined, DesktopOutlined, LoadingOutlined, ShareAltOutlined } from "@ant-design/icons"
import { Tooltip, Spin, message, Popover, Button, Popconfirm } from "antd"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { copyText } from '@/utils/util'
import Power from './power'
import Attachment from './attachment'
import { deleteLayout } from '@/axios/axios.scene'
import { useVersion } from '@/hooks/version'


export default memo(function ({
    onReload,
    scene,
}) {
    // const account = useSelector(state => state.account)
    const { t } = useTranslation()
    const [deleting, setDeleting] = useState(false)
    const { versioning, versions, onVersions } = useVersion(scene.id, false)

    const onShare = () => {
        const url = `${window.location.origin}${window.location.pathname}#/scene/editor/${scene.plant}/read/${scene.id}/${scene.design_id}/latest`
        if (copyText(url)) {
            message.success(t('scene.layout.share'))
        }
    }

    const onDelete = () => {
        setDeleting(true)
        deleteLayout(scene.id)
            .then(() => {
                onReload()
            })
            .finally(() => {
                setDeleting(false)
            })
    }

    return (
        <section className="f-s-c">
            <Tooltip title={t('common.share')}>
                <ShareAltOutlined className="c-p c-hover-app c-666 ease-400 m-r-10" onClick={onShare} />
            </Tooltip>
            {/* 去掉删除按钮 */}
            {/* {
                scene.isSelf ? 
                <Tooltip title={t('common.delete')}>
                    {
                        deleting ?
                            <LoadingOutlined className="m-r-10 c-n" /> :
                            <Popconfirm
                                title={t('common.notice')}
                                description={t('common.notice-del')}
                                onConfirm={onDelete}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined className="c-p c-hover-app c-666 ease-400 m-r-10" />
                            </Popconfirm>
                    }
                </Tooltip> :
                null
            } */}
            <Tooltip title={t('common.release')}>
                <Popover
                    placement="bottom"
                    content={<div>
                        <Spin spinning={versioning}>
                            {
                                versions.map(o=>(
                                    // <Link className="d-b" key={o.id} to={`/scene/editor/${o.plant}/read/${o.id}/${o.design_id}/${o.version}`}>
                                    <Link className="d-b" key={o.id} to={`/scene/editor/${o.plant}/read/${scene.id}/${o.id}/${o.version}`}>
                                        <Button type="link">
                                            {o.version}
                                        </Button>
                                    </Link>
                                ))
                            }
                        </Spin>
                    </div>} 
                    trigger="click"
                >
                    <DesktopOutlined className="c-p c-hover-app c-666 ease-400 m-r-10" onClick={onVersions} />
                </Popover>
            </Tooltip>
            {
                scene.isSelf ?
                <Power scene={scene} onReload={onReload}></Power> :
                null
            }
            <Attachment scene={scene} onReload={onReload} />
        </section>
    )
})
